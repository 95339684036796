<template>
  <ContentfulRenderer v-if="data" class="contentful-renderer" />
</template>

<script lang="ts" setup>
import { HyperLink } from '#components'
import {
  type Block,
  BLOCKS,
  type Document,
  type Inline,
  INLINES,
  MARKS,
  type Text,
} from '@contentful/rich-text-types'
import { LcImage } from '@lottocom/frontend-components'
import { isVNode } from 'vue'

import type {
  Asset,
  ContentBlockContentAssets,
  TrustCertificatesDescriptionAssets,
} from '~/@types/generated/cms/graphql-schema-types'

type Content = Block | Inline | Text

type ContentfulRendererProps = {
  data?: Maybe<{
    json: Document
    links?: Maybe<{
      assets: ContentBlockContentAssets | TrustCertificatesDescriptionAssets
    }>
  }>
}

const HEADING_MAP: Partial<Record<BLOCKS, string>> = {
  [BLOCKS.HEADING_1]: 'h1',
  [BLOCKS.HEADING_2]: 'h2',
  [BLOCKS.HEADING_3]: 'h3',
  [BLOCKS.HEADING_4]: 'h4',
  [BLOCKS.HEADING_5]: 'h5',
  [BLOCKS.HEADING_6]: 'h6',
}

const BLOCK_MAP: Partial<Record<BLOCKS, string>> = {
  [BLOCKS.HR]: 'hr',
  [BLOCKS.LIST_ITEM]: 'li',
  [BLOCKS.OL_LIST]: 'ol',
  [BLOCKS.PARAGRAPH]: 'p',
  [BLOCKS.TABLE]: 'table',
  [BLOCKS.TABLE_CELL]: 'td',
  [BLOCKS.TABLE_HEADER_CELL]: 'th',
  [BLOCKS.TABLE_ROW]: 'tr',
  [BLOCKS.UL_LIST]: 'ul',
}

const MARK_MAP: Partial<Record<MARKS, string>> = {
  [MARKS.BOLD]: 'strong',
  [MARKS.CODE]: 'code',
  [MARKS.ITALIC]: 'em',
  [MARKS.UNDERLINE]: 'u',
}

const props = defineProps<ContentfulRendererProps>()

const ContentfulRenderer = computed(() => {
  const content = props.data?.json.content ?? []
  return () =>
    h(
      'article',
      {},
      {
        default: () => content.map(getBlock),
      },
    )
})
const imageMap = computed(() => {
  const map = new Map<string, Asset>()
  props.data?.links?.assets?.block?.forEach((asset) => {
    if (!asset) return
    map.set(asset.sys.id, asset)
  })
  return map
})

const getBlock = ({ content, data, nodeType }: Block): Maybe<VNode> => {
  if (Object.keys(HEADING_MAP).includes(nodeType)) {
    const children = content.map(getContent)
    const textContent = children.map(getTextContent).join('')

    return h(
      HEADING_MAP[nodeType]!,
      { id: getId(textContent) },
      {
        default: () => children,
      },
    )
  }

  if (Object.keys(BLOCK_MAP).includes(nodeType)) {
    return h(
      BLOCK_MAP[nodeType]!,
      {},
      {
        default: () => content.map(getContent),
      },
    )
  }

  if (nodeType === BLOCKS.EMBEDDED_ASSET) {
    const asset = imageMap.value.get(data.target.sys.id)
    return asset
      ? h(LcImage, {
          alt: asset.title ?? '',
          class: 'inline-block',
          height: asset.height ?? 0,
          src: asset.url ?? '',
          width: asset.width ?? 0,
        })
      : h(
          'div',
          {},
          `inline: ${BLOCKS.EMBEDDED_ASSET}, sys.id: ${data.target.sys.id}`,
        )
  }

  return null
}

const getContent = (content: Content): Maybe<string | VNode> => {
  if (isBlock(content)) return getBlock(content)
  if (isInline(content)) return getInline(content)
  if (isText(content)) return getText(content)
  return null
}

const getTextContent = (content: unknown | VNode): string => {
  if (typeof content === 'string') return content
  if (typeof content === 'number') return content.toString()

  if (isVNode(content) && typeof content.children === 'string')
    return content.children

  if (isVNode(content) && Array.isArray(content.children))
    return content.children.map(getTextContent).join('')

  return ''
}

const getId = (textContent: string) =>
  textContent
    .toLowerCase()
    .trim()
    .replace(/[^\w]/g, '-') // Replace all characters that are not alphanumeric with hyphens
    .replace(/_/g, '-') // Replaces underscores with hyphens
    .replace(/-+/g, '-') // Replaces multiple hyphens with a single hyphen
    .replace(/^-|-$/g, '') // Remove leading and trailing hyphens

const getInline = ({ content, data, nodeType }: Inline) => {
  if (nodeType === INLINES.HYPERLINK) {
    return h(
      HyperLink,
      { class: 'text-link', to: data.uri },
      {
        default: () => content.map(getContent),
      },
    )
  }

  return null
}

const getText = ({ marks, value }: Text) => {
  let markedValue: string | VNode = value
  marks.forEach(({ type }) => {
    if (!Object.keys(MARK_MAP).includes(type)) return
    markedValue = h(MARK_MAP[type as MARKS]!, {}, markedValue ?? false)
  })
  return markedValue
}

const isBlock = (value: Content): value is Block =>
  Object.values(BLOCKS).includes((value as Block).nodeType)

const isInline = (value: Content): value is Inline =>
  Object.values(INLINES).includes((value as Inline).nodeType)

const isText = (value: Content): value is Text =>
  (value as Text).nodeType === 'text'
</script>

<style lang="scss">
.contentful-renderer {
  b {
    font-weight: $font-weight-medium;
  }

  hr {
    height: 0.0625rem;
    margin: spacing('md') 0;
    border-width: 0.0625rem 0 0;
    border-style: solid;
    color: color('secondary400');
  }

  i {
    font-style: italic;
  }

  table {
    width: 100%;

    td,
    th {
      vertical-align: middle;
    }

    th {
      border-bottom: separator('line');
      font-weight: $font-weight-medium;
      font-size: $font-size-h5;
    }

    > tbody,
    > thead {
      > tr {
        > td,
        > th {
          padding: spacing('sm') 0;
        }
      }
    }

    > tbody {
      > tr + tr {
        border-top: separator('line');
      }
    }
  }

  u {
    text-decoration: underline;
  }
}
</style>
